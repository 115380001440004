import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: none;
  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    position: fixed;
    right: 32px;
    top: 0px;
    bottom: 0px;
    transition: opacity 0.3s;
    z-index: 100;
  }
`;

const DotGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  background-color: #1D385E;
  border-radius: 50%;
  transition-duration: 0.3s;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
`;

const DotLabel = styled.div`
  font-family: Inter;
  color: #1D385E;
  font-size: 14px;
  transition-duration: 0.3s;
  line-height: 1;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  margin-right: 16px;
  font-weight: 300;
  border: solid 1px #1D385E;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  pointer-events: none;
`;

const GetVisibility = (id) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    var element = document.getElementById(id);
    if (element != null) {
      var top = element.getBoundingClientRect().top + (window.innerHeight / 2);
      var bottom = element.getBoundingClientRect().bottom - (window.innerHeight / 2);
      setVisible(top < window.innerHeight && bottom > 0);
    }
  }, [scrollTop]);
  return visible;
}

const scroll = (id, offset) => {
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.scrollY + offset;
  window.scrollTo({top: y, behavior: "smooth"});
};

const NavDots = (props) => {

  const { data } = props;

  const [dotHovered, setDotHovered] = React.useState(null);

  const scrolling = React.useRef(null);

  const dots =
    <Wrapper>
      {data.map((dot, i) => (
          <DotGroup key={i} >
            <DotLabel style={{ "opacity": dotHovered == dot.id ? 1 : 0 }}>
              {dot.label}
            </DotLabel>
            <Dot 
              onClick={() => scroll(dot.id, -150)}
              onMouseEnter={() => setDotHovered(dot.id)}
              onMouseLeave={() => setDotHovered("")}
              style={{ "opacity": GetVisibility(dot.id) && !scrolling.current ? 0.8 : 0.2 }} 
            />
          </DotGroup>
        ))}
    </Wrapper>

  return dots;
};

export default NavDots;
