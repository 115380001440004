import React, { useEffect } from "react";
import styled from "styled-components";

const ImageWrapper = styled.img`
  display: none;
  @media (min-width: 1200px) {
    display: flex;
    position: relative;
    z-index: 300;
  }
`;

const Image = (props) => {

  const { width, path } = props;

  return (
    <ImageWrapper style={{ "width": width }} src={path} />
  )
}

export default Image;
