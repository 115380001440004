import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NavigationDots from "./nav-dots";
import Plot from "./plot";
import { BlockPage, Block, BlockTitle, BlockSubtitle, BlockText, BlockByline } from "./block-system";
import { capitalize } from "./utilities";
import Axios from "axios";

import StateCard from "./state-card";

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100vw - 32px);
  margin-top: 50px;
  margin-bottom: 50px;
  @media (min-width: 600px) {
    width: 80vw;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

const Button = styled.button`
  font-family: Inter;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 2px #1D385E;
  font-size: 16px;
  font-weight: 600;
  color: #1D385E;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background-color: #EAECF0;
  }
`;

const MembersPage = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [data, setData] = React.useState(undefined);

  const getData = () => {
    setIsLoaded(false);
    Axios.get("https://www.api.partisanship.org/states")
      .then(response => {
        console.log(response.data)
        setData(response.data);
        setIsLoaded(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getData();
  }, [])

  var cards;
  if (isLoaded) {
    cards =
      <CardWrapper>
        {data.map((item, i) => (
          <Link key={i} to={"/members/" + item.state_code} >
            <StateCard key={i} data={item} />
          </Link>
        ))}
      </CardWrapper>
  }

  const navData = [
    { id: "block-1", label: "Overview" },
    { id: "block-2", label: "Select a state" },
  ];

  const page =
    <BlockPage>
      <Block id="block-1" width={700} color="white" nextColor="light" shape={1}>
        <BlockTitle>
          How partisan are members of Congress in their speeches?
        </BlockTitle>
        <BlockByline>
          By Josh Fjelstul, PhD
        </BlockByline>
        <BlockText>
          There are 535 members of Congress — 435 members of the House of Representatives and 100 members of the Senate. Partisanship.org assigns each member — both representatives in the House and senators in the Senate — a score that captures how partisan they are based on the language they use in their legislative speeches.
        </BlockText>
        <BlockText>
          Here's how it works. I start by collecting the text of all speeches made by members of Congress during 118th Congress (2023-present). Next, I train an ML model that, for each paragraph in those speeches, predicts the party of the speaker based only on the text. I use this trained model to calculate, for every paragraph, the probability that the speaker is a Democrat versus a Republican.
        </BlockText>
        <BlockText>
          Once I have a probability for each paragraph, I transform them into scores that range from -10 to +10. These scores characterizes how partisan a paragraph is and whether it's Democrat-leaning or Repubican-leaning. The intuition is pretty simple — if it's easy to predict a member's party based on what they say, they're more partisan. If it's hard, they're less partisan, and more non-partisan.
        </BlockText>
        <BlockText>
          Finally, I average these paragraph-level scores by member to calculate an overall score for each member. I give a higher weight to more recent paragraphs. This keeps the scores current, while still leveraging data from previous sessions. 
        </BlockText>
        <ButtonWrapper>
          <Button as={Link} to="/methodology">
            Learn more.
          </Button>
        </ButtonWrapper>
        <BlockSubtitle>
          How to interpret Partisanship.org scores
        </BlockSubtitle>
        <BlockText>
          Partisanship.org scores can range from -10 to +10. Scores have a magnitude and a polarity. The <b>magnitude</b> (the absolute value) indicates how partisan the statement is, and the <b>polarity</b> (the sign) indicates whether the statement is Democrat-leaning (negative) or Republican-leaning (positive).
        </BlockText>
        <BlockText>
          Members who have scores that are further away from 0, and that are closer to -10 or +10, are more partisan than members who have scores closer to 0. Members who have scores closer to -10 are more partisan in a Democrat-leaning direction, and members who have scores closer to +10 are more partisan in a Republican-leaning direction.
        </BlockText>
      </Block>
      <Block id="block-2" width={700} color="light" nextColor="dark" shape={2}>
        <BlockTitle>
          Select a state
        </BlockTitle>
        <BlockText>
          Select a card below to see all members of a state's congressional delegation. Each state has at least one representative and two senators. From there, you can select a specific member and see how their Partisanship.org score compares to the scores of other members of Congress and other members of their state's congressional delegation.
        </BlockText>
        {cards}
      </Block>
      <NavigationDots data={navData} />
    </BlockPage>

  return page;
};

export default MembersPage;
