import styled from "styled-components";

const CardPaper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 300px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  transition-duration: 0.3s;
  @media (min-width: 600px) {
    &:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    }
  }
`;

const CardImageWrapper = styled.div`
  display: flex;
  overflow: hidden;
  width: 300px;
  height: 300px;
  border-bottom: solid 2px #1D385E;
`;

const CardImage = styled.img`
  width: 100%;
  @media (min-width: 600px) {
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    ${CardPaper}:hover & {
      transform: scale(1.1);
    }
  }
`;

const CardContent = styled.div`
  width: calc(300px - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
`;

const CardTitle = styled.div`
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  color: #1D385E;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const CardName = styled.div`
  font-family: Inter;
  font-size: 20px;
  color: #1D385E;
  font-weight: 700;
  margin-bottom: 4px;
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

`;

const CardParty = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #1D385E;
  margin-left: 8px;
`;

const CardState = styled.div`
  font-family: Inter;
  font-size: 14px;
  color: #1D385E;
  font-weight: 300;
  margin-bottom: 12px;
`;

const CardScore = styled.div`
  font-family: Inter;
  font-size: 60px;
  font-weight: 700;
  color: #1D385E;
  margin-bottom: -12px;
`;

const StateCard = (props) => {
  var { data } = props;

  var file;
  if (data.chamber === "House") {
    file = "/plots/district-maps/district-map-" + data.district_code + ".svg";
  }
  if (data.chamber === "Senate") {
    file = "/plots/state-maps/state-map-" + data.state_code + ".svg";
  }

  var card =
    <CardPaper>
      <CardImageWrapper>
        <CardImage src={file} />
      </CardImageWrapper>
      <CardContent>
        <CardTitle>
          {data.chamber === "House" ? "Representative" : "Senator"}
        </CardTitle>
        <CardName>
          {data.first_name} {data.last_name} <CardParty style={{ "color": data.party_code === "R" ? "#C56655" : "#6183C0" }} >({data.party_code})</CardParty>
        </CardName>
        <CardState>
          {data.state}{data.chamber === "House" ? (", " + data.district) : ""}
        </CardState>
        <CardScore style={{ "color": data.party_code === "R" ? "#C56655" : "#6183C0" }} >
          {data.score_formatted === undefined ? "?" : data.score_formatted}
        </CardScore>
      </CardContent>
    </CardPaper>

  return card;
}

export default StateCard;
