import styled from "styled-components";
import InteractiveMap from "./interactive-map";

const MapWrapper = (props) => {

  const { data, type } = props;

  const MakeHouseTooltip = (d) => {
    return (
      `<div class='tooltip-wrapper'>
        <div class='tooltip-member-wrapper'>
          <div class='tooltip-score' style='color:${d.properties.party_code === "R" ? "#C56655" : "#6183C0"};'>
            ${typeof (d.properties.score_formatted) !== "string" ? "?" : d.properties.score_formatted}
          </div>
          <div class='tooltip-content'>
            <div class='tooltip-title'>
              Representative
            </div>
            <div class='tooltip-name'>
              ${d.properties.first_name} ${d.properties.last_name}
              <span class='tooltip-party' style='color:${d.properties.party_code === "R" ? "#C56655" : "#6183C0"};'>
                (${d.properties.party_code})
              </span>
            </div>
            <div class='tooltip-state'>
              ${d.properties.state}, ${d.properties.district}
            </div>
          </div>
        </div>
      </div>`
    )
  };

  const MakeSenateTooltip = (d) => {
    return (
      `<div class='tooltip-wrapper'>
        <div class='tooltip-member-wrapper'>
          <div class='tooltip-score' style='color:${d.properties.senate_party_code_1 === "R" ? "#C56655" : "#6183C0"};'>
            ${typeof (d.properties.senate_score_formatted_1) !== "string" ? "?" : d.properties.senate_score_formatted_1}
          </div>
          <div class='tooltip-content'>
            <div class='tooltip-title'>
              Senator
            </div>
            <div class='tooltip-name'>
              ${d.properties.senate_first_name_1} ${d.properties.senate_last_name_1}
              <span class='tooltip-party' style='color:${d.properties.senate_party_code_1 === "R" ? "#C56655" : "#6183C0"};'>
                (${d.properties.senate_party_code_1})
              </span>
            </div>
            <div class='tooltip-state'>
              ${d.properties.state}
            </div>
          </div>
        </div>
        <div class='tooltip-divider'></div>
        <div class='tooltip-member-wrapper'>
          <div class='tooltip-score' style='color:${d.properties.senate_party_code_2 === "R" ? "#C56655" : "#6183C0"};'>
            ${typeof (d.properties.senate_score_formatted_2) !== "string" ? "?" : d.properties.senate_score_formatted_2}
          </div>
          <div class='tooltip-content'>
            <div class='tooltip-title'>
              Senator
            </div>
            <div class='tooltip-name'>
              ${d.properties.senate_first_name_2} ${d.properties.senate_last_name_2}
              <span class='tooltip-party' style='color:${d.properties.senate_party_code_2 === "R" ? "#C56655" : "#6183C0"};'>
                (${d.properties.senate_party_code_2})
              </span>
            </div>
            <div class='tooltip-state'>
              ${d.properties.state}
            </div>
          </div>
        </div>
      </div>`
    )
  };

  var map;


  if (type === "house") {
    map =
      <InteractiveMap id="house-map" data={data} height={500} width={700} selection={false} MakeTooltip={MakeHouseTooltip} projected={true} color="score_color" />

  }

  if (type === "senate") {
    map =
      <InteractiveMap id="senate-map" data={data} height={500} width={700} selection={false} MakeTooltip={MakeSenateTooltip} projected={true} color="color" />

  }

  if (type === "state") {
    map =
      <InteractiveMap id="house-map" data={data} height={500} width={500} selection={false} MakeTooltip={MakeHouseTooltip} projected={true} color="score_color" />
  }

  return map;
}

export default MapWrapper;
