import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const white = "#FFFFFF"
const light = "#F7F9FC";
// const light = "#F1EFEB"
const dark = "#1D385E";
const primary = "#6183C0";
const text = dark;
// const text = "#4D4C4A";

const BlockPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  margin-top: 100px;
  @media (min-width: 900px) {
    margin-top: 0px;
  }
`;

const Text = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  @media (min-width: 600px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const BlockText = (props) => {

  var { color } = props;

  if (color === undefined) {
    color = "dark";
  }

  if (color === "white") {
    color = white;
  }

  if (color === "dark") {
    color = text;
  }

  return (
    <Text style={{ "color": color }}>
      {props.children}
    </Text>
  )
}

const Title = styled.div`
  font-family: "Libre Baskerville";
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 16px;
  width: 100%;
  @media (min-width: 480px) {
    font-size: 36px;
  }
`;

const BlockTitle = (props) => {

  var { color } = props;

  if (color === undefined) {
    color = "dark";
  }

  if (color === "white") {
    color = white;
  }

  if (color === "dark") {
    color = text;
  }

  return (
    <Title style={{ "color": color }}>
      {props.children}
    </Title>
  )
}

const Subtitle = styled.div`
  font-family: "Libre Baskerville";
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  margin-top: 32px;
  margin-bottom: 8px;
  width: 100%;
  @media (min-width: 480px) {
    margin-bottom: 12px;
    font-size: 24px;
  }
`;

const BlockSubtitle = (props) => {

  var { color } = props;

  if (color === undefined) {
    color = "dark";
  }

  if (color === "white") {
    color = white;
  }

  if (color === "dark") {
    color = text;
  }

  return (
    <Subtitle style={{ "color": color }}>
      {props.children}
    </Subtitle>
  )
}

const BlockByline = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #1D385E;
  margin-top: 8px;
  margin-bottom: 16px;
  width: 100%;
`;

const BlockColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const BlockColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: calc(100vw - 32px);
  @media (min-width: 600px) {
    width: 500px;
  }
  @media (min-width: 900px) {
    width: 700px;
  }
  @media (min-width: 1200px) {
    margin-top: 0px;
    margin-left: 50px;
    margin-right: 50px;
    width: 400px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: -1px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: unset;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 600px) {
    width: 500px;
  }
  @media (min-width: 900px) {
    width: 700px;
  }
  @media (min-width: 1200px) {
    width: 700px;
  }
`;

const Target = styled.div`
  position: absolute;
  top: -100px;
`;

const Block = (props) => {

  var { id, shape, color, nextColor } = props;

  const targetRef = useRef();

  const [targetElement, setTargetElement] = React.useState();

  useEffect(() => {
    setTargetElement(targetRef.current);
  }, []);

  var TopShape;
  var BottomShape;

  if (shape === 1) {

    TopShape = "M 0 96 L 1024 0 L 0 0 L 0 96 Z";

    BottomShape = "M 0 96 L 1024 0 C 1024 0 1024 96 1024 96 C 1024 96 0 96 0 96 Z";
  }

  if (shape === 2) {

    TopShape = "M 0 0 L 1024 96 L 1024 0 L 0 0 Z";

    BottomShape = "M 0 0 L 0 96 L 1024 96 L 0 0 Z";
  }

  if (shape === 3) {

    TopShape = "M 0 0 L 512 96 L 1024 0 L 0 0 Z";

    BottomShape = "M 0 0 L 0 96 L 1024 96 L 1024 0 L 512 96 L 0 0 Z";

  }

  if (shape === undefined) {
    shape = 0;
  }

  var scale = "scale(1, 1)";

  if (color === "white") {
    color = white;
  }

  if (color === "light") {
    color = light;
  }

  if (color === "dark") {
    color = dark;
  }

  if (color === "primary") {
    color = primary;
  }

  if (nextColor === "white") {
    nextColor = white;
  }

  if (nextColor === "light") {
    nextColor = light;
  }

  if (nextColor === "dark") {
    nextColor = dark;
  }

  if (nextColor === "primary") {
    nextColor = primary;
  }

  var separator =
    <div style={{ "height": "50px" }} />

  if (shape !== 0) {
    separator =
      <svg width="100%" height="100%" viewBox="0 0 1024 96" style={{ "width": "100%", "transform": scale }}>
        <path d={TopShape} style={{ "fill": color }} />
        <path d={BottomShape} style={{ "fill": nextColor }} />
      </svg>
  }

  return (
    <Wrapper id={id} style={{ "backgroundColor": color }}>
      <Content targetElement={targetElement} opacity={[0, 1, "easeInQuad"]}>
        <Target ref={targetRef} />
        {props.children}
      </Content>
      {separator}
    </Wrapper>
  );
}

export { BlockPage, Block, BlockColumns, BlockColumn, BlockTitle, BlockSubtitle, BlockText, BlockByline };
