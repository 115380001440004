import React, { useState, useEffect, useRef } from "react";
import * as d3selection from 'd3-selection';
import * as d3geo from 'd3-geo';
import * as d3zoom from 'd3-zoom';

// renderFunction is a callback that contains the D3 code
// dependencies is an array of with any objects that should trigger an update
const useD3 = (renderFunction, dependencies) => {
  const ref = useRef();
  useEffect(() => {
    renderFunction(d3selection.select(ref.current));
    return () => { };
  }, dependencies)
  return ref;
}

export default function Map(props) {

  const { id, data, width, height, MakeTooltip, selection, projected, color } = props;

  const ref = useD3(
    () => {

      var svg = d3selection.select(ref.current)
      
      svg.selectAll("*").remove()

      svg.attr("width", width).attr("height", height)

      // define variables
      var selectedFeature = d3selection.select(null);
      var shadedFeature = d3selection.select(null);
      var featureUnderMouse = d3selection.select(null);
      var featureUnderMouseOnZoom = d3selection.select(null);
      var zoomingOn = false;
      var mousePosition = [,];

      // ==================================================
      // create the projection
      // ==================================================

      // define the projection
      if (projected) {
        var projection = d3geo.geoIdentity()
          .reflectY(true)
          .scale(1)
          .translate([0, 0])
      } else {
        var projection = d3geo.geoMercator()
          .scale(1)
          .translate([0, 0])
      }

      // create a new path
      var path = d3geo.geoPath()

      // add theprojection
      path = d3geo.geoPath()
        .projection(projection)

      // calculate bounds
      var bounds = path.bounds(data)

      // calculate new scale
      var scale = 0.9 / Math.max((bounds[1][0] - bounds[0][0]) / width, (bounds[1][1] - bounds[0][1]) / height)

      // calculate new translation
      var translation = [(width - scale * (bounds[1][0] + bounds[0][0])) / 2, (height - scale * (bounds[1][1] + bounds[0][1])) / 2]

      // update the projection
      projection = projection
        .scale(scale)
        .translate(translation)

      // ==================================================
      // draw the map
      // ==================================================

      // line width
      var g = svg.append("g")
        .attr("stroke-width", 0.5 + "px")

      // append paths
      var d = g.selectAll("path")
        .data(data.features)
        .enter()
        .append("path")
        .attr("d", path)
        .style("fill", d => {
          return d.properties[color]
        })
        .style("stroke", "white")

      // ==================================================
      // mouse events related to features
      // ==================================================

      if (selection === true) {
        d.on('mouseenter', onMouseEnter)
          .on("mouseleave", onMouseLeave)
          .on("click", onClick)
      } else {
        d.on('mouseenter', onMouseEnter)
          .on("mouseleave", onMouseLeave)
      }

      // ==================================================
      // mouse events related to the svg element
      // ==================================================

      if (selection === true) {
        svg.on("click", deselectFeature)
          .on("mousemove", onMouseMove)
      } else {
        svg.on("mousemove", onMouseMove)
      }

      // ==================================================
      // zoom functions
      // ==================================================

      // function to handle zooming
      var zoom = d3zoom.zoom()
        .translateExtent([[0, 0], [width, height]])
        .scaleExtent([1, 25])
        .on("start", onZoomStart)
        .on("zoom", onZoom)
        .on("end", onZoomEnd)

      // call the zoom function
      svg.call(zoom)

      // runs when zooming starts
      function onZoomStart() {

        // mark that zooming is starting to disallow other mouse events
        zoomingOn = true

        // turn tooltip off
        turnTooltipOff()

        // record which country the mouse is over when zooming starts
        featureUnderMouseOnZoom = featureUnderMouse
      }

      // runs while zooming
      function onZoom(event) {

        var x = d3selection.pointer(this)[0] + document.getElementById(id).getBoundingClientRect().x
        var y = d3selection.pointer(this)[1] + document.getElementById(id).getBoundingClientRect().y
        mousePosition = [x, y]

        // move tooltip
        updateTooltipPosition()

        // bounded x change
        var xChange = Math.min(0, Math.max(event.transform.x, width - width * event.transform.k))

        // bounded y change
        var yChange = Math.min(0, Math.max(event.transform.y, height - height * event.transform.k))

        // update transform
        g.attr("transform", "translate(" + [xChange, yChange] + ") scale(" + event.transform.k + ")")

        // update line width to keep it constant
        d.attr("stroke-width", 0.5 / event.transform.k + "px")
      }

      // runs when zooming ends
      function onZoomEnd() {

        // mark that zooming is ending to allow other mouse events
        zoomingOn = false

        // get the feature under the mouse
        // featureUnderMouse = d3.select(document.elementFromPoint(mousePosition[0], mousePosition[1]))

        // if the mouse end position is over water
        //featureUnderMouse.node() === document.getElementById("map")
        if (featureUnderMouse.node() === null) {

          // unshade currently shaded feature
          shadedFeature.classed("hovering", false)

          // clean currently shaded feature
          shadedFeature = d3selection.select(null)

          // turn off tooltip
          turnTooltipOff()

          // if the mouse end position is over land
        } else {

          // if mouse end position is over the same country as when zooming started
          if (featureUnderMouse.node() === featureUnderMouseOnZoom.node()) {

            // turn the tooltip back on
            turnTooltipOn()

            // if mouse end position is over a different country than when zooming started
          } else {

            // unshade the currently shaded country (if any)
            shadedFeature.classed("hovering", false)

            // shade the country that is currently under the mouse (if any)
            shadedFeature = featureUnderMouse.classed("hovering", true)

            // turn the tooltip back on
            turnTooltipOn()
          }
        }
      }

      // ==================================================
      // feature selection functions
      // ==================================================

      // // function to select a feature
      function selectFeature(event, d) {

        // unselect the currently selected feature (if there is one)
        selectedFeature.classed("selected", false)

        // calculate bounds of the country that was clicked on
        var bounds = path.bounds(d),

          // x range
          dx = bounds[1][0] - bounds[0][0],

          // y range
          dy = bounds[1][1] - bounds[0][1],

          // half
          x = (bounds[0][0] + bounds[1][0]) / 2,

          // half
          y = (bounds[0][1] + bounds[1][1]) / 2,

          // new scale
          scale = Math.max(1, Math.min(25, 0.9 / Math.max(dx / width, dy / height))),

          // new translate
          translate = [width / 2 - scale * x, height / 2 - scale * y];

        // zoom in on this feature
        event.stopPropagation()
        svg.transition().duration(1000).call(
          zoom.transform,
          d3zoom.zoomIdentity
            .translate(translate[0], translate[1])
            .scale(scale)
        )
      }

      // function to deselect a feature
      function deselectFeature(event) {

        // change the CSS class of the currently selected feature
        svg.transition().duration(400).call(zoom.transform, d3zoom.zoomIdentity)

        selectedFeature.classed("selected", false)

        // unselect the currently selected feature
        selectedFeature = d3selection.select(null)
      }

      // ==================================================
      // tooltip functions
      // ==================================================

      // make the tooltip
      var tooltip = d3selection.select("body")
        .append("div")
        .attr("class", "tooltip-anchor")
        .style("opacity", 0)

      // function to turn on the tooltip
      function turnTooltipOn() {

        // apply transition
        tooltip.transition()
          .duration(250)
          .style("opacity", 1)
      }

      // function to turn off the tooltip
      function turnTooltipOff() {

        // apply transition
        tooltip.transition()
          .duration(250)
          .style("opacity", 0)
      }

      // function to update tooltip position
      function updateTooltipPosition() {
        tooltip.style("left", (mousePosition[0] - 75) + "px").style("top", (mousePosition[1] + 40) + "px")
      }

      // ==================================================
      // mouse event functions
      // ==================================================

      // function to show the tooltip
      function onMouseEnter(event, d) {

        // raise the selected country to the front (to handle borders)
        d3selection.select(this).raise()

        // record the country being selected
        // featureUnderMouse = d3.select(document.elementFromPoint(mousePosition[0], mousePosition[1]))
        featureUnderMouse = d3selection.select(this)

        var tooltipHtml = MakeTooltip(d);

        // update the tooltip HTML
        tooltip.html(tooltipHtml);

        // if not currently zooming
        if (zoomingOn === false) {

          // turn on the tooltip
          turnTooltipOn()

          // unshade the shaded feature (if any)
          shadedFeature.classed("hovering", false)

          // shade the currently shaded feature
          shadedFeature = d3selection.select(this).classed("hovering", true)
        }
      }

      // function to close the tooltip
      function onMouseLeave() {

        // clear the country recorded as being under the mouse
        featureUnderMouse = d3selection.select(null)

        // if not currently zooming
        if (zoomingOn === false) {

          // turn off the tooltip
          turnTooltipOff()

          // unshade the currently shaded feature (if any)
          shadedFeature.classed("hovering", false)

          // unshade the currently shaded feature
          shadedFeature = d3selection.select(null)
        }
      }

      // function to update the position of the tooltip when the mouse moves
      function onMouseMove(event) {

        var x = d3selection.pointer(event)[0] + document.getElementById(id).getBoundingClientRect().x
        var y = d3selection.pointer(event)[1] + document.getElementById(id).getBoundingClientRect().y
        mousePosition = [x, y]

        // update the tooltip position
        updateTooltipPosition()
      }

      // runs when the mouse is clicked
      function onClick(event, d) {

        // if this is the currently selected feature
        if (selectedFeature.node() === this) {

          // deselect the feature
          deselectFeature()

          // if this is not the currently selected feature
        } else {

          selectedFeature.classed("selected", false)

          // select this feature
          selectedFeature = d3selection.select(this).classed("selected", true)
          
          // calculate bounds of the country that was clicked on
          var bounds = path.bounds(d)

          // x range
          var xRange = bounds[1][0] - bounds[0][0]

          // y range
          var yRange = bounds[1][1] - bounds[0][1]

          // divide range by 2
          var x = (bounds[0][0] + bounds[1][0]) / 2

          // divide range by 2
          var y = (bounds[0][1] + bounds[1][1]) / 2

          // new scale
          var scale = Math.max(1, Math.min(20, 0.6 / Math.max(xRange / width, yRange / height)))

          // new translate
          var translate = [width / 2 - scale * x, height / 2 - scale * y]

          // zoom in on this feature
          event.stopPropagation()
          svg.transition().duration(400).call(
            zoom.transform,
            d3zoom.zoomIdentity
              .translate(translate[0], translate[1])
              .scale(scale)
          )
        }
      }
    },
    [data]
  )

  return (
    <svg className="map" id={id} ref={ref} />
  )
}
