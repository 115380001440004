// react components
import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

// Pages
import HomePage from "./home-page";
import ChamberPage from "./chamber-page";
import StatePage from "./state-page";
import MembersPage from "./members-page";
import MemberPage from "./member-page";
import MethodologyPage from "./methodology-page";

const memberData = require("./member-data.json");
const stateData = require("./state-data.json");

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0px;
  z-index: 1000;
  display: flex;
  height: 80px;
  width: 100vw;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  @media (min-width: 900px) {
    position: relative;
    box-shadow: none;
    height: 100px;
  }
`;

const Header = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 100;
  background-color: #ffffff;
  @media (min-width: 900px) {
    background-color: unset;
  }
`;

const HeaderTabs = styled.div`
  display: none;
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    margin-right: 50px;
  }
`;

const HeaderTab = styled(Link)`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  color: #1D385E;
  margin-left: 16px;
  margin-right: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 5px;
  padding-bottom: 3px;
  border-bottom: solid 2px #ffffff;
  opacity: 0.5;
  transition: 0.3s;
  &:hover {
    color: #1D385E;
    border-bottom: solid 2px #1D385E;
    opacity: 1;
  }
  @media (min-width: 900px) {
    margin-left: 16px;
    margin-right: 0px;
  }
  @media (min-width: 1200px) {
    margin-left: 50px;
    margin-right: 0px;
  }
`;

const HeaderTabActive = styled(HeaderTab)`
  padding-bottom: 3px;
  border-bottom: solid 2px #1D385E;
  opacity: 1;
`;

const BrandingWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 32px;
  margin-right: 32px;
  @media (min-width: 900px) {
    margin-left: 50px;
    margin-right: 50px;
  }
`;

const BrandingImage = styled.img`
  height: 50px;
  @media (min-width: 900px) {
    height: 70px;
  }
`;

const BrandingText = styled.div`
  // font-family: "Monsieur La Doulaise";
  font-family: "Tangerine";
  font-size: 36px;
  font-weight: 400;
  color: #1D385E;
  margin-left: 12px;
  @media (min-width: 900px) {
    font-size: 46px;
  }
`;

const BrandingTextWhite = styled(BrandingText)`
  color: #ffffff;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #1D385E;
  margin-top: -1px;
  padding-top: 32px;
`;

const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 60px;
`;

const SocialButton = styled.img`
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

const Copyright = styled.div`
  font-family: Inter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  color: white;
  opacity: 0.3;
  font-size: 10pt;
  font-weight: 300;
`;

const MenuButton = styled.img`
  height: 24px;
  margin-right: 32px;
  cursor: pointer;
  @media (min-width: 900px) {
    display: none;
  }
`;

const Menu = styled.div`
  position: absolute;
  z-index: -1;
  top: 80px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 32px;
  padding-bottom: 16px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  transition-duration: 0.3s;
  @media (min-width: 900px) {
    display: none;
  }
`;

const MenuItem = styled(Link)`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  color: #1D385E;
  padding-top: 16px;
  padding-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transform-duration: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;


export default function App(props) {

  var location = useLocation();

  const [menuOpen, setMenuOpen] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //--------------------------------------------------
  // page
  //--------------------------------------------------

  const page =
    <Routes>
      <Route path="/" exact element={<HomePage />} />
      <Route path="/home" exact element={<HomePage />} />
      <Route path="/house" exact element={<ChamberPage chamber="house" />} />
      <Route path="/senate" exact element={<ChamberPage chamber="senate" />} />
      <Route path="/members" exact element={<MembersPage />} />
      <Route path="/methodology" exact element={<MethodologyPage />} />
      {stateData.map((item, i) => (
        <Route key={i} path={"/members/" + item.state_code} exact element={<StatePage stateData={item} />} />
      ))}
      {memberData.map((item, i) => (
        <Route key={i} path={"/members/" + item.bioguide_id} exact element={<MemberPage memberData={item} />} />
      ))}
    </Routes>

  //--------------------------------------------------
  // header
  //--------------------------------------------------

  const makeHeaderTab = (label, to) => {
    var tab;
    if (location.pathname.includes(to)) {
      tab =
        <HeaderTabActive>
          {label}
        </HeaderTabActive>
    } else {
      tab =
        <HeaderTab to={to}>
          {label}
        </HeaderTab>
    }
    return tab;
  }

  const header =
    <HeaderWrapper>
      <Header>
        <BrandingWrapper to="/home" onClick={() => setMenuOpen(false)}>
          <BrandingImage src="/logo.svg" />
          <BrandingText>
            Partisanship.org
          </BrandingText>
          {/* <BrandingText>
            Partisanship<span style={{ "opacity": 0.3, "fontSize": "18px" }}>.org</span>
          </BrandingText> */}
        </BrandingWrapper>
        <HeaderTabs>
          {makeHeaderTab("House", "/house")}
          {makeHeaderTab("Senate", "/senate")}
          {makeHeaderTab("Members", "/members")}
          {makeHeaderTab("Methodology", "/methodology")}
        </HeaderTabs>
        <MenuButton src={menuOpen ? "/close.svg" : "/menu.svg"} onClick={() => setMenuOpen(!menuOpen)} />
      </Header>
      <Menu style={{ "transform": (menuOpen ? "translate(0px, 0px)" : "translate(0px, -250px)") }}>
        <MenuItem to="/house" onClick={() => setMenuOpen(false)}>
          House
        </MenuItem>
        <MenuItem to="/senate" onClick={() => setMenuOpen(false)}>
          Senate
        </MenuItem>
        <MenuItem to="/members" onClick={() => setMenuOpen(false)}>
          Members
        </MenuItem>
        <MenuItem to="/methodology" onClick={() => setMenuOpen(false)}>
          Methodology
        </MenuItem>
      </Menu>
    </HeaderWrapper>

  //--------------------------------------------------
  // footer 
  //--------------------------------------------------

  const footer =
    <Footer>
      <BrandingWrapper>
        <BrandingImage src="/logo-white.svg" />
        <BrandingTextWhite to="/home">
          Partisanship.org
        </BrandingTextWhite>
      </BrandingWrapper>
      <SocialButtons>
        <a href="https://www.linkedin.com/in/jfjelstul/">
          <SocialButton src="/social-logos/linkedin-logo.svg" />
        </a>
        <a href="https://github.com/jfjelstul">
          <SocialButton src="/social-logos/github-logo.svg" />
        </a>
        <a href="https://www.x.com/joshfjelstul">
          <SocialButton src="/social-logos/x-logo.svg" />
        </a>
        {/* <SocialButton src="/social-logos/instagram-logo.svg" /> */}
        {/* <SocialButton src="/social-logos/threads-logo.svg" /> */}
      </SocialButtons>
      <Copyright>
        © 2023 J. C. Fjelstul Consulting LLC
      </Copyright>
    </Footer>

  return (
    <div>
      {header}
      {page}
      {footer}
    </div>
  );
};
