import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Parallax } from "react-scroll-parallax";

const PlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  @media (min-width: 900px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const PlotTitle = styled.div`
  font-family: "Libre Baskerville";
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 16px;
  color: #1D385E;
  width: 100%;
  @media (min-width: 900px) {
    font-size: 36px;
  }
`;

const PlotSubtitle = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 32px;
  color: #1D385E;
  width: 100%;
  @media (min-width: 900px) {
    font-size: 20px;
  }
`;

const PlotImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const PlotImage = styled.object`
  width: 100%;
`;

const PlotTarget = styled.div`
  position: absolute;
  top: -100px;
`;

// targetElement={targetElement} translateY={["100px", "0px", "easeInOutQuad"]}
// targetElement={targetElement} opacity={[0, 1, "easeInQuad"]}

const Plot = (props) => {
  const { title, subtitle, path, map } = props;

  const targetRef = useRef();

  const [targetElement, setTargetElement] = React.useState();

  useEffect(() => {
    setTargetElement(targetRef.current);
  }, []);

  var image;
  if (path !== undefined) {
    image = <PlotImage data={path} />
  } else {
    image = map
  }

  return (
    <PlotWrapper targetElement={targetElement} opacity={[0, 1, "easeInQuad"]}>
      <PlotTarget ref={targetRef} />
      <PlotTitle targetElement={targetElement} translateY={["50px", "0px", "easeInOutQuad"]} >
        {title}
      </PlotTitle>
      <PlotSubtitle targetElement={targetElement} translateY={["100px", "0px", "easeInOutQuad"]}>
        {subtitle}
      </PlotSubtitle>
      <PlotImageWrapper targetElement={targetElement} translateY={["150px", "0px", "easeInOutQuad"]}>
        {image}
      </PlotImageWrapper>
    </PlotWrapper>
  )
}

export default Plot;
