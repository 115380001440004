import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import styled from "styled-components";

import { BlockPage, Block, BlockColumns, BlockColumn, BlockTitle, BlockSubtitle, BlockText } from "./block-system";
import NavigationDots from "./nav-dots";
import Image from "./image";

const SplashBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - 100px);
`;

const SplashImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80px;
  bottom: 130px;
  left: 0px;
  right: 0px;
  overflow: hidden;
`;

const SplashImage = styled.img`
  height: 100%;
`;

const SplashTextWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 50px;
  text-align: center;
  height: 100px;
`;

const SplashText = styled.span`
  font-family: "Libre Baskerville";
  font-style: italic;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #1D385E;
  color: #1D385E;
  line-height: 1.7;
`;

const SplashTextBold = styled.span`
  font-family: "Libre Baskerville";
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left: 8px;
  margin-right: 8px;
  @media (min-width: 900px) {
    font-size: 24px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

const Button = styled.button`
  font-family: Inter;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 32px;
  border: solid 2px #1D385E;
  font-size: 16px;
  font-weight: 600;
  color: #1D385E;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background-color: #EAECF0;
  }
`;

const ToolWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  @media (min-width: 600px) {
    width: 500px;
  }
  @media (min-width: 900px) {
    width: 700px;
  }
  @media (min-width: 1200px) {
    width: 900px;
  }
`;

const QuillWrapper = styled.div`
  display: none;
  transition-duration: 0.4s;
  @media (min-width: 900px) {
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.03;
    position: absolute;
    z-index: -1;
    margin-top: -100px;
  }
`;

const QuillImage = styled.img`
  width: 100%;
  display: block;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  width: calc(100vw - 32px);
  @media (min-width: 600px) {
    width: 100%;
  }
`;

const InputBox = styled.textarea`
  resize: none;
  width: 100%;
  padding-bottom: 13px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Libre Baskerville";
  color: #1D385E;
  background-color: rgba(255, 255, 255, 0);
  line-height: 1.6;
  border: none;
  border-bottom: solid 2px #1D385E;
  position: relative;
  outline: none;
`;

const InputOpenQuote = styled.img`
  display: none;
  @media (min-width: 600px) {
    display: flex;
    position: relative;
    width: 150px;
    height: 150px;
    margin-bottom: -73px;
    margin-left: -42px;
  }
`;

const InputWordCount = styled.div`
  font-family: Inter;
  font-size: 14px;
  color: #1D385E;
  opacity: 0.5;
  width: 100%;
  text-align: right;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const InputButton = styled(Button)`
  margin-top: 0px;
`;

const OutputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
  @media (min-width: 600px) {
    width: 500px;
`;

const OutputScore = styled.div`
  font-family: Inter;
  text-align: center;
  font-size: 120px;
  font-weight: 700;
  border-bottom: solid 2px #1D385E;
  margin-bottom: 8px;
  text-align: center;
  width: 100%;
  @media (min-width: 600px) {
    font-size: 140px;
  }
`;

const OutputSummary = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  color: #1D385E;
  width: 100%;
`;

const examples = [
  "In just 2 years of failed one party-Democrat rule, the American people have suffered from a historic border crisis, rampant crime, crippling inflation, rising energy costs, runaway debt, unconstitutional attacks on our fundamental freedoms, and weakness at home and abroad. — Elise Stefanik",
  "KEVIN MCCARTHY is a strong conservative. He is proudly pro-life, a supporter of our Second Amendment rights, and he is committed to stopping wasteful government spending and shrinking the size of government. — Elise Stefanik",
  "Today, Madam Clerk, House Democrats are united, united by a Speaker who will put people over politics. HAKEEM JEFFRIES has worked his entire life to improve economic opportunity for all people. He is committed to strengthening the American Dream by lowering costs for working families, building safer communities by taking weapons of war off streets, and by creating good-paying jobs in industries of the future. — Pete Aguilar",
  "From voting rights to reproductive rights, we are united, Madam Clerk. We are unified behind a Speaker who is an unapologetic advocate for protecting and expanding our freedoms. He does not traffic in extremism. He does not grovel to or make excuses for a twice-impeached, so-called former President. — Pete Aguilar"
];

const HomePage = (props) => {

  // search form fields
  const [inputText, setInputText] = React.useState("Example text.");
  const [rawScore, setRawScore] = React.useState(0.0);
  const [wordCount, setWordCount] = React.useState(10);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [exampleIndex, setExampleIndex] = React.useState(0);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = Math.min(scrollHeight, 500) + "px";
  }, [inputText]);

  const textareaRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    var index = Math.floor(Math.random() * 4);
    updateText(examples[index]);
    getScore(examples[index]);
  }, [])

  const getScore = (text) => {
    setIsLoaded(false);
    const url = "https://www.api.partisanship.org/prediction";
    Axios.post(url, { "text": text })
      .then(response => {
        setRawScore(response.data.prediction);
        setIsLoaded(true);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleButton = () => {
    getScore(inputText);
  }

  const getNewExample = () => {
    var index = exampleIndex + 1;
    if (index > examples.length - 1) {
      index = 0;
    }
    setExampleIndex(index);
    updateText(examples[index]);
    getScore(examples[index]);
  }

  const updateText = (text) => {
    var words = text.trim().split(/\s+/);
    var count = words.length;
    var newText;
    if (count > 99) {
      words = words.slice(0, 100);
      newText = words.join(" ").trim();
    } else {
      newText = text;
    }
    setInputText(newText);
    setWordCount(Math.min(count, 100));
  }

  var score = rawScore * 20 - 10

  var partisanship;
  if (score >= -10 & score < -8) {
    partisanship = "extremely partisan";
  }
  if (score >= -8 & score < -6) {
    partisanship = "very partisan";
  }
  if (score >= -6 & score < -4) {
    partisanship = "moderately partisan";
  }
  if (score >= -4 & score < -2) {
    partisanship = "only slightly partisan";
  }
  if (score >= -2 & score < 0) {
    partisanship = "relatively non-partisan";
  }
  if (score >= 0 & score < 2) {
    partisanship = "relatively non-partisan";
  }
  if (score >= 2 & score < 4) {
    partisanship = "only slightly partisan";
  }
  if (score >= 4 & score < 6) {
    partisanship = "moderately partisan";
  }
  if (score >= 6 & score <= 8) {
    partisanship = "very partisan";
  }
  if (score >= 8 & score <= 10) {
    partisanship = "extremely partisan";
  }

  var scoreDisplay = score.toFixed(2);
  if (rawScore < 0) {
    scoreDisplay = "?";
  }
  if (score > 0) {
    scoreDisplay = "+" + scoreDisplay;
  }

  var polarity;
  if (score < 0) {
    polarity = "Democratic-leaning";
  }
  if (score >= 0) {
    polarity = "Republican-leaning";
  }

  const navData = [
    { id: "block-1", label: "Partisanship.org" },
    { id: "block-2", label: "Analyze a quote" },
    { id: "block-3", label: "What is partisanship?" },
    { id: "block-4", label: "Partisanship vs ideology" },
    { id: "block-5", label: "About Partisanship.org" },
  ];

  const handleKeyDown = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
    e.target.style.height = `${Math.min(e.target.scrollHeight, 500)}px`;
  }

  const page =
    <BlockPage>
      <SplashBlock id="block-1">
        <SplashImageWrapper>
          <SplashImage src="/background-images/background-large-1.png" />
        </SplashImageWrapper>
        <SplashTextWrapper>
          {/* <SplashText>Using</SplashText><SplashTextBold>machine learning</SplashTextBold><SplashText>to measure</SplashText><SplashTextBold>partisanship</SplashTextBold><br/>
          <SplashText>in the</SplashText><SplashTextBold>United States Congress</SplashTextBold> */}
          <SplashText>Using<SplashTextBold>machine learning</SplashTextBold>to measure<SplashTextBold>partisanship</SplashTextBold><br/>
          in the<SplashTextBold>United States Congress</SplashTextBold></SplashText>
        </SplashTextWrapper>
      </SplashBlock>
      <Block id="block-2" color="light" nextColor="white" shape={1} >
        <div style={{ "marginTop": "50px" }} />
        <BlockTitle>
          Estimate the partisanship of political speech with ML
        </BlockTitle>
        <BlockText>
          <b>Partisanship.org</b> uses a state-of-the-art machine learning (ML) model to estimate the partisanship of members of the United States Congress — representatives in the House and senators in the Senate — based on the speeches they make on the floor. You can use the iteractive tool below to estimate the partisanship of any political statement (up to 100 words) in real time based on this ML model.
        </BlockText>
        <BlockText>
          In the United States, the <b>partisanship</b> of a person's political speech is <i><b>the degree to which their speech is consistent with the typical political speech of Democrats versus Republicans.</b></i> A nonpartisan statement is one that could easily come from a Democrat or a Republican. A partisan statement is one that's far more likely to come from a member of one party than from a member of the other party.
        </BlockText>
        <BlockText>
          Paste a quote from a member of Congress into the text box below — or  make up your own quote — and get an ML-powered estimate of how partisan it is. You'll get a <b>Partisanship.org score</b> on a scale from -10 to +10 that indicates how partisan the quote is and whether it's partisan in a Democratic-leaning or Republican-leaning direction. Try editing your quote to see how changes in language affect how partisan it is!
        </BlockText>
        <ToolWrapper>
          <QuillWrapper>
            <QuillImage src="quill.svg" />
          </QuillWrapper>
          <InputWrapper>
            <InputOpenQuote src="quote-open.svg" />
            <InputBox
              value={inputText}
              onChange={e => updateText(e.target.value)}
              spellCheck={false}
              ref={textareaRef}
            />
            <InputWordCount>
              {wordCount} / 100 words
            </InputWordCount>
            <ButtonRow>
            <InputButton onClick={handleButton}>
              Get score.
            </InputButton>
            <InputButton onClick={getNewExample}>
              New example.
            </InputButton>
            </ButtonRow>
          </InputWrapper>
          <OutputWrapper>
            <OutputScore style={{ color: score >= 0 ? "#C56655" : "#6183C0" }}>
              {scoreDisplay}
            </OutputScore>
            <OutputSummary>
              The Partisanship.org score for this statement is {scoreDisplay}. This statement is {polarity} (polarity) and {partisanship} (magnitude).
            </OutputSummary>
          </OutputWrapper>
        </ToolWrapper>
        <BlockSubtitle>
          How do you interpret Partisanship.org scores?
        </BlockSubtitle>
        <BlockText>
          Partisanship.org scores can range from -10 to +10. Scores have a magnitude and a polarity. The <b>magnitude</b> (the absolute value) indicates how partisan the statement is, and the <b>polarity</b> (the sign) indicates whether the statement is Democrat-leaning or Republican-leaning.
        </BlockText>
        <BlockText>
          Statements that have scores that are further away from 0, and that are closer to -10 or +10, are more partisan than statements that have scores closer to 0. Statements that have scores closer to -10 are more partisan in a Democrat-leaning direction, and statements that have scores closer to +10 are more partisan in a Republican-leaning direction.
        </BlockText>
        <BlockText>
          These scores measure partisanship by capturing how likely a statement is to come from a member of one party versus the other based only on the language used in the statement. A statement with a score close to -10 is very likely to come from a Democrat, and a statement with a score close to +10 is very likely to come from a Republican. A statement with a score close to 0 could come from a member of either party.
        </BlockText>
        <ButtonWrapper>
          <Button as={Link} to="/methodology">
            Learn more.
          </Button>
        </ButtonWrapper>
      </Block>
      <Block id="block-3" color="white" nextColor="light" shape={1} >
        <BlockColumns>
          <BlockColumn>
            <Image width="130%" path="/background-images/background-medium-1.png" />
          </BlockColumn>
          <BlockColumn>
            <BlockTitle>
              What is partisanship?
            </BlockTitle>
            <BlockText>
              The <b>partisanship</b> of a person's political speech is <i><b>the degree to which their speech is consistent with the typical political speech of Democrats versus Republicans.</b></i> A nonpartisan statement is one that could easily come from a Democrat or a Republican. A partisan statement is one that's far more likely to come from a member of one party than from a member of the other party. A strong partisan, then, is someone who's always in lock-step with their party in terms of their political speech. Partisanship.org scores measure a person's partisanship.
            </BlockText>
          </BlockColumn>
        </BlockColumns>
      </Block>
      <Block id="block-4" color="light" nextColor="white" shape={1}>
        <BlockTitle>
          Partisanship vs ideology
        </BlockTitle>
        <BlockText>
          Partisanship.org scores measure <i><b>partisanship, not ideology</b></i>. Ideology is a person's location on a left/right political spectrum. It's about a person's political views. Partisanship is about how consistent a person's speech is with the typical speech of members of one political party versus the typical speech of members of another party. You can be ideologically extreme, but moderately partisan. And you can be extremely partisan, but ideologically moderate.
        </BlockText>
        <BlockText>
          Here's an example. Bernie Sanders is a self-described democratic socialist. In terms of ideology, we'd place him on the far-left of the American political spectrum. But he's not a particularly partisan Democrat, and that's because his political speech isn't as consistent with the typical political speech of Democrats as other members' speech is. He doesn't toe the party line as much as other Democrats. This is precisely because his political views are to the left of mainstream Democrats. At the same time, even if what he's saying isn't exactly in line with what other Democrats are saying, you're not likely to mistake him for a Republican.
        </BlockText>
        <BlockText>
          Chuck Schumer, on the other hand, is ideologically moderate. He's a mainstream Democrat. But he's extremely partisan. He's the majority leader in the Senate, and as a leader of the Democratic Party, it's his job to shape the Democrats' message. He helps to define what the typical speech of Democrats is, and that makes him strongly partisan.
        </BlockText>
      </Block>
      <Block id="block-5" color="white" nextColor="dark" shape={2}>
        <BlockColumns>
          <BlockColumn>
            <BlockTitle>
              About
            </BlockTitle>
            <BlockText>
              Partisanship.org was created by Dr. Josh Fjelstul. Josh is a data scientist who specializes in machine learning (ML) and natural language processing (NLP). He has a Ph.D. in political science from Emory University. He's the founder of J. C. Fjelstul Consulting LLC.
            </BlockText>
            <BlockText>
              Partisanship.org is an independent project and doesn't recieve any funding from any source.
            </BlockText>
          </BlockColumn>
          <BlockColumn>
            <Image width="130%" path="/background-images/background-medium-4.png" />
          </BlockColumn>
        </BlockColumns>
      </Block>
      <NavigationDots data={navData} />
    </BlockPage>

  return page;
};

export default HomePage;
