import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/CloudDownload";

// API components
import Axios from "axios";

function scroll (id, offset) {
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
  window.scrollTo({top: y, behavior: 'smooth'});
};

function jump (id, offset) {
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
  window.scrollTo({top: y});
};

function link (value, path) {
  if (path.includes('http')) {
    return (
      <a className='link' href={path}>{value}</a>
    );
  } else {
    return (
      <Link className='link' to={path}>{value}</Link>
    );
  }
};

function code (string) {
  return <span className="code">{string}</span>
};

function bold (string) {
  return <span className="bold">{string}</span>
};

function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function makeCard (title, description, image, selected, onClick) {
    return(
      <div className='card'>
        <div className='card-container'>
          <div className='card-image-container'>
            <img className='card-image' src={image} />
          </div>
          <div className='card-content-container'>
            <div className='card-title'>
              {title}
            </div>
            <div className='card-description'>
              {description}
            </div>
          </div>
        </div>
      </div>
    )
}

function parseText (string) {
  var newString = string;
  newString = newString.replaceAll("{", "<span class='code'>");
  newString = newString.replaceAll("}", "</span>");
  return(newString);
}

function makePlot (image) {
  const plot =
  <div className='plot-container'>
    <img className='plot' src={image}/>
    {/* <div className='plot-download-button-container'>
      <div className='plot-download-button-label'>
        Download
      </div>
      <a href={image} download='HGIMT-plot.png'>
        <DownloadIcon className='plot-download-button'/>
      </a>
    </div> */}
  </div>
  return(plot);
}

function trackAction (type, value) {
  const getDate = () => {
    var today = new Date().toString();
    return(today);
  }
  const url = "https://api.iuropa.pol.gu.se/CJEU-database-platform/track-action";
  const body = { "type": type, "value": value };
  Axios.post(url, body)
  .then(response => {

  })
  .catch(error => {
    console.log(error);
  });
};

export { scroll, jump, link, bold, code, capitalize, parseText, makeCard, makePlot, trackAction };
