import styled from "styled-components";

const CardPaper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 200px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  padding-bottom: 16px;
  overflow: hidden;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }
`;

const CardImageWrapper = styled.div`
  overflow: hidden;
  width: 200px;
  height: 200px;
  border-bottom: solid 2px #1D385E;
`;

const CardImage = styled.img`
  width: 200px;
  transform: scale(1);
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  ${CardPaper}:hover & {
    transform: scale(1.1);
  }
`;

const CardTitle = styled.div`
  font-family: Inter;
  font-size: 20px;
  color: #1D385E;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 8px;
  padding-left: 16px;
`;

const CardText = styled.div`
  font-family: Inter;
  font-size: 13px;
  color: #1D385E;
  opacity: 0.5;
  font-weight: 400;
  padding-left: 16px;
  line-height: 1.5;
`;

const StateCard = (props) => {
  var { data } = props;
  return (
    <CardPaper>
      <CardImageWrapper>
        <CardImage src={"plots/state-maps/state-map-" + data.state_code + ".svg"} />
      </CardImageWrapper>
      <CardTitle>
        {data.state}
      </CardTitle>
      <CardText>
        {data.count_districts} {data.count_districts > 1 ? "representatives" : "representative"}
        <br/>
        2 senators
      </CardText>
    </CardPaper>
  )
}

export default StateCard;
