import React, { useEffect } from "react";
import styled from "styled-components";
import NavigationDots from "./nav-dots";
import Plot from "./plot";
import { BlockPage, Block, BlockColumns, BlockColumn, BlockSubtitle, BlockTitle, BlockText } from "./block-system";
import { capitalize } from "./utilities";
import Axios from "axios";

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  @media (min-width: 900px) {
    width: 90vw;
    flex-direction: row;
  }
`;

const MemberSummary = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  @media (min-width: 900px) {
    margin-top: 0px;
    align-items: flex-start;
  }
`;

const MemberTitle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  color: #1D385E;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 2px;
  @media (min-width: 900px) {
    font-size: 14px;
  }
`;

const MemberName = styled.div`
  font-family: Inter;
  font-size: 24px;
  color: #1D385E;
  font-weight: 700;
  margin-bottom: 4px;
  margin-left: 16px;
  @media (min-width: 900px) {
    font-size: 36px;
    margin-left: 0px;
  }
`;

const MemberParty = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #1D385E;
  margin-left: 8px;
  @media (min-width: 900px) {
    font-size: 20px;
  }
`;

const MemberState = styled.div`
  font-family: Inter;
  font-size: 16px;
  color: #1D385E;
  font-weight: 300;
  margin-bottom: 16px;
  @media (min-width: 900px) {
    font-size: 20px;
  }
`;

const MemberScore = styled.div`
  font-family: Inter;
  font-size: 50px;
  font-weight: 700;
  color: #1D385E;
  @media (min-width: 900px) {
    font-size: 80px;
  }
`;

const MemberImage = styled.img`
  height: 300px;
  margin-right: 0px;
  @media (min-width: 900px) {
    margin-right: 50px;
  }
`;

const MemberMap = styled.img`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 8px;
  @media (min-width: 600px) {
    width: 400px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const MemberPage = (props) => {

  const { memberData } = props;

  // search form fields
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [data, setData] = React.useState(undefined);

  const getData = () => {
    setIsLoaded(false);
    Axios.get("https://www.api.partisanship.org/member/" + memberData.bioguide_id)
      .then(response => {
        console.log(response)
        setData(response.data);
        setIsLoaded(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getData();
  }, [])

  var intro;
  var page;
  var navData;

  if (isLoaded) {

    var chamber = data.chamber;

    navData = [
      { id: "block-1", label: "Overview" },
      { id: "block-2", label: "Location in the " + chamber },
      { id: "block-3", label: "Location in state delegation" },
      { id: "block-4", label: "Consistency of partisanship" },
    ];

    var mapFile;
    if (data.chamber == "House") {
      mapFile = "/plots/district-maps/district-map-" + data.district_code + ".svg"
    }
    if (data.chamber == "Senate") {
      mapFile = "/plots/state-maps/state-map-" + data.state_code + ".svg"
    }

    intro =
      <MemberWrapper>
        <MemberImage src={"/member-images/" + data.bioguide_id + ".png"} />
        <MemberSummary>
          <MemberTitle>
            {data.chamber === "House" ? "Representative" : "Senator"}
          </MemberTitle>
          <MemberName>
            {data.first_name} {data.last_name} <MemberParty style={{ "color": data.party_code === "R" ? "#C56655" : "#6183C0" }} >({data.party_code})</MemberParty>
          </MemberName>
          <MemberState>
            {data.state}{data.chamber === "House" ? (", " + data.district) : ""}
          </MemberState>
          <MemberScore style={{ "color": data.party_code === "R" ? "#C56655" : "#6183C0" }} >
            {data.score_formatted === undefined ? "?" : data.score_formatted}
          </MemberScore>
        </MemberSummary>
      </MemberWrapper>

    page =
      <BlockPage>
        <Block id="block-1" width={900} color="white" nextColor="light" shape={1}>
          {intro}
          {/* <MemberMap src={mapFile} /> */}
          <BlockText>
            {data.first_name} {data.last_name} is a current member of the {data.chamber}. {capitalize(data.personal_pronoun)}'s a {data.party}, and {data.personal_pronoun} represents {data.chamber === "House" ? "the " + data.district + " in" : "the state of"} {data.state} ({data.state_code}). 
          </BlockText>
          <BlockText>
            {data.title} {data.last_name} has a Partisanship.org score of {data.score_formatted}. Partisanship.org scores can range from -10 to +10. Members who have scores that are further away from 0, and that are closer to -10 or +10, are more partisan than members who have scores closer to 0. Members who have scores closer to -10 are more partisan in a Democrat-leaning direction, and members who have scores closer to +10 are more partisan in a Republican-leaning direction.
          </BlockText>
          <BlockText>
            Based on {data.possessive_pronoun} Partisanship.org score, {data.title} {data.last_name} is {data.partisanship_label}. Compared to the average member of the {data.chamber}, {data.personal_pronoun}'s {data.relative_partisanship_label}.
          </BlockText>
          <BlockText>
            {capitalize(data.possessive_pronoun)} Partisanship.org score is based on {data.count_speeches_formatted} legislative speeches, which contain {data.count_paragraphs_formatted} paragraphs of text and {data.word_count_formatted} words. In terms of how much {data.personal_pronoun}'s spoken during the 118th Congress (2023-present), {data.personal_pronoun} ranks {data.word_count_rank_in_chamber} out of the {data.count_in_chamber} members of the {data.chamber}. {capitalize(data.personal_pronoun)} ranks {data.word_count_rank_in_party} out of the {data.count_in_party} members of the {data.party_long} in the {data.chamber} and {data.word_count_rank_in_state} out of the {data.count_in_state} members from the state of {data.state}.
          </BlockText>
        </Block>
        <Block id="block-2" width={"50%"} color="light" nextColor="white" shape={1}>
          <Plot
            title={"Location in the " + data.chamber}
            subtitle={
              <span>
                The Partisanship.org score for {data.title} <span style={{ "fontWeight": "600", "color": data.party_color }}>{data.first_name} {data.label}</span> compared to all current members of the {data.chamber}
              </span>
            }
            caption=""
            path={"/plots/member-plots/chamber-plots/" + data.bioguide_id + "-score.svg"}
          />
          <BlockText>
            This visualization shows how the current Partisanship.org score for {data.title} {data.last_name} compares to the scores for all members of the {data.chamber}. {capitalize(data.possessive_pronoun)} Partisanship.org score is {data.score_formatted} on a scale from -10 to 10. {capitalize(data.personal_pronoun)}'s the {data.score_rank_in_chamber} most partisan member of the {data.count_in_chamber_with_score} members of the {data.chamber} who have a score and the {data.score_rank_in_party} most partisan member of the {data.count_in_party_with_score} members of the {data.party_long} in the {data.chamber} who have a score.
          </BlockText>
          <BlockText>
            Each point is a member of the {data.chamber}. {data.title} {data.last_name} is labeled. The <i>x</i>-axis (horizontal) indicates each member's Partisanship.org score. The <i>y</i>-axis indicates each member. On the <i>y</i>-axis, members are sorted by score. Members with more positive scores are closer to the top, and members with more negative scores are closer to the bottom. The <i>y</i> positions of the points are vertically jittered to make the plot easier to read.
          </BlockText>
        </Block>
        <Block id="block-3" width={"50%"} color="white" nextColor="light" shape={1}>
          <Plot
            title={"Location in state delegation"}
            subtitle={
              <span>
                The Partisanship.org score for {data.title} <span style={{ "fontWeight": "600", "color": data.party_color }}>{data.first_name} {data.label}</span> compared to all current members of the delegation from {data.state}
              </span>
            }
            caption=""
            path={"/plots/member-plots/delegation-plots/" + data.bioguide_id + "-delegation.svg"}
          />
          <BlockText>
            This visualization shows how the current Partisanship.org score for {data.title} {data.last_name} compares to those for other members of the congressional delegation from the state of {data.state}. A state's congressional delegation includes all representatives in the House and both senators in the Senate. Based on the magnitude of {data.possessive_pronoun} Partisanship.org score, {data.title} {data.last_name} is the {data.score_rank_in_state} most partisan member of the congressional delegation from {data.state} out of the {data.count_in_state_with_score} members who have a score.
          </BlockText>
          <BlockText>
            Each point is a member of the current congressional delegation from the state of {data.state}. {data.title} {data.last_name} is labeled. The <i>x</i>-axis (horizontal) indicates each member's Partisanship.org score. The <i>y</i>-axis (vertical) doesn't have an interpretation. Members are staggered on the <i>y</i>-axis to make the plot easier to read. The size of each circle indicates the number of words that each member's Partisanship.org score is based on.
          </BlockText>
        </Block>
        <Block id="block-4" width={"50%"} color="light" nextColor="dark" shape={2} flip={true}>
          <Plot
            title={"Consistency of partisanship"}
            subtitle={
              <span>
                The distribution of Partisanship.org scores for all paragraphs in floor speeches delivered by {data.title} <span style={{ "fontWeight": "600", "color": data.party_color }}>{data.first_name} {data.label}</span> compared to {data.possessive_pronoun} overall Partisanship.org score
              </span>
            }
            caption=""
            path={"/plots/member-plots/density-plots/" + data.bioguide_id + "-density.svg"}
          />
          <BlockText>
            This visualization shows the distribution of paragraph-level Partisanship.org scores for all paragraphs in legislative speaches made by {data.title} {data.last_name}. The shape of this distribution indicates both how partisan {data.personal_pronoun} is in {data.possessive_pronoun} congressional speeches and how consistent {data.personal_pronoun} is in {data.possessive_pronoun} partisanship across {data.possessive_pronoun} speeches. Based on the mean of this distribution (weighted by the recency of the paragraph), {data.title} {data.last_name} is {data.partisanship_label}. Based on the interquartile range of this distribution, {data.personal_pronoun}'s {data.consistency_label} in {data.possessive_pronoun} partisanship across speeches.
          </BlockText>
          <BlockText>
            The <i>x</i>-axis shows all possible values for paragraph-level Partisanship.org scores, which can range from -10 to +10. The <i>y</i>-axis shows the density of the distribution at each possible value on the <i>x</i>-axis. The density is the relative proportion of paragraphs that are located near each possible score. The vertical dashed line indicates the member's overall Partisanship.org score. A member's overall Partisanship.org score is a weighted average of these paragraph-level scores, where more recent paragraphs carry more weight.
          </BlockText>
          <BlockText>
            The more skewed the distribution is towards one end of the scale or the other, the more partisan a member is. Skewness is about how asymmetrical a distribution is. In a skewed distribution, one side will have a longer tail than the other. In general, when the distribution has positive skew (a long right tail), there will be more density on the left side of the scale, and the member will be partisan in a Democratic-leaning direction. When the distribution has negative skew (a long left tail), there will be more density on the right side of the scale, and the member will be partisan in a Republican-leaning direction.
          </BlockText>
          <BlockText>
            The less dispersed the distribution is, the more consistent a member is in their partisanship. Dispersion is about how spread out the distribution is. We can measure how dispersed the distribution of a member's paragraph-level Partisanship.org scores is by calculating the interquartile range. The interquartile range of a distribution is the difference between the 75th and 25th percentiles. Since the distribution of paragraph-level Partisanship.org scores tends to be highly skewed, the interquartile range is a better measure of dispersion than the standard deviation.
          </BlockText>
        </Block>
        <NavigationDots data={navData} />
      </BlockPage>

    if (data.score_formatted === undefined) {

      page =
        <BlockPage>
          <Block id="block-1" width={900} color="white" nextColor="dark" shape={2}>
            {intro}
            <BlockText>
              {data.first_name} {data.last_name} is a current member of the {data.chamber}. {capitalize(data.personal_pronoun)}'s a {data.party}, and {data.personal_pronoun} represents {data.chamber === "House" ? "the " + data.district + " in" : "the state of"} {data.state} ({data.state_code}). {data.title} {data.last_name} hasn't spoken enough yet to recieve a Partisanship.org score.
            </BlockText>
          </Block>
        </BlockPage>
    }
  }

  return page;
};

export default MemberPage;
