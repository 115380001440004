import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NavigationDots from "./nav-dots";
import Plot from "./plot";
import MapWrapper from "./map-wrapper";
import { BlockPage, Block, BlockTitle, BlockText } from "./block-system";
import { capitalize } from "./utilities";
import Axios from "axios";

import MemberCard from "./member-card";

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 32px);
  margin-top: 50px;
  margin-bottom: 50px;
  @media (min-width: 600px) {
    width: 80vw;
  }
`;

const StateMap = styled.img`
  margin-top: 50px;
  width: calc(100vw - 32px);
  border: solid 1px #EAECF0;
  border-radius: 8px;
  @media (min-width: 600px) {
    width: 400px;
  }
`;

const MapTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 16px;
  color: #1D385E;
  width: 100%;
`;

const MapSubtitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 32px;
  color: #1D385E;
`;

const StatePage = (props) => {

  const { stateData } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [data, setData] = React.useState(undefined);
  const [mapData, setMapData] = React.useState(undefined);

  const getData = () => {
    setIsLoaded(false);
    Axios.get("https://www.api.partisanship.org/members-by-state/" + stateData.state_code)
      .then(response => {
        setData(response.data);
        setIsLoaded(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const loadMapData = () => {
    const mapData = require("./state-map-data/map-data-" + stateData.state_code + ".json");
    setMapData(mapData);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    loadMapData();
  }, [data])

  const navData = [
    { id: "block-1", label: "Overview" },
    { id: "block-2", label: "Congressional delegation" },
    { id: "block-3", label: "Congressional districts" },
    { id: "block-4", label: "Select a state" },
  ];

  var page;
  if (isLoaded) {

    page =
      <BlockPage>
        <Block id="block-1" color="white" nextColor="light" shape={1}>
          <BlockTitle>
            Members of the 118th Congress from the state of {stateData.state}
          </BlockTitle>
          <BlockText>
            Each state sends a delegation to Congress that includes representatives who serve in the House and senators who serve in the Senate. Each state has two senators and at least one representative. The current senators from {stateData.state} are {stateData.senate_first_name_1} {stateData.senate_last_name_1} ({stateData.senate_party_code_1}) and {stateData.senate_first_name_2} {stateData.senate_last_name_2} ({stateData.senate_party_code_2}). {stateData.state} has {stateData.count_districts} seats in the House.
          </BlockText>
          <StateMap src={"/plots/state-maps/state-map-" + stateData.state_code + ".svg"} />
        </Block>
        <Block id="block-2" color="light" nextColor="white" shape={1}>
          <Plot
            title={
              <span>
                Congressional delegation
              </span>
            }
            subtitle={
              <span>
                Partisanship.org scores for members of the congressional delegation from the state of {stateData.state} for the 118th Congress
              </span>
            }
            path={"/plots/state-plots/state-delegation-plots/" + stateData.state_code + "-delegation.svg"}
          />
          <BlockText>
            This visualization shows the current Partisanship.org score for all members of the congressional delegation from the state of {data.state}. A state's congressional delegation includes all representatives in the House and both senators in the Senate.
          </BlockText>
          <BlockText>
            Each point is a member of the current congressional delegation from the state of {stateData.state}. The <i>x</i>-axis (horizontal) indicates each member's Partisanship.org score. The <i>y</i>-axis (vertical) doesn't have an interpretation. Members are staggered on the <i>y</i>-axis to make the plot easier to read. The size of each circle indicates the number of words that each member's Partisanship.org score is based on.
          </BlockText>
        </Block>
        <Block id="block-3" color="white" nextColor="light" shape={1}>
          <Plot
            title={
              <span>
                Congressonal districts
              </span>
            }
            subtitle={
              <span>
                The geographic distribution of Partisanship.org scores by congressional district for members of the House from {stateData.state} in the 118th Congress (2023-present)
              </span>
            }
            map={
              <MapWrapper data={mapData} type="state" />
            }
          />
          <BlockText>
            This map shows the Partisanship.org score for each member of the House from {stateData.state} by congressional distribt. {stateData.state} has {stateData.count_districts} districts. The color of each district indicates the member's Partisanship.org score. Darker blues indicate more partisan Democrats and darker reds indicate more partisan Republicans. The color is based on the percentile of the score within the member's party. The gray color indicates that there is not yet enough data to estimate an accurate score for the member.
          </BlockText>
          <BlockText>
            This map is interactive. If you're on a computer, you can hover your mouse over each district to bring up a tooltip that shows the Partisanship.org score for the member who represents that district. If you're using a trackpad, you can two-finger zoom or pinch zoom to zoom in and out. If you're using a mouse, you can use your scroll button. When you're zoomed in, you can click and drag to pan the map.
          </BlockText>
        </Block>
        <Block id="block-4" color="light" nextColor="dark" shape={2}>
          <BlockTitle>
            Select a state
          </BlockTitle>
          <BlockText>
            Select a card below to get more information about a specific member of Congress from the state of {stateData.state}. You can see how the member's Partisanship.org score compares to the scores of other members of Congress and other members of the congressional delegation from {stateData.state}. You can also see how consistent the member is in their partisanship across legislative speeches.
          </BlockText>
          <CardWrapper>
            {data.map((item, i) => (
              <Link to={"/members/" + item.bioguide_id}>
                <MemberCard data={item} />
              </Link>
            ))}
          </CardWrapper>
        </Block>
        <NavigationDots data={navData} />
      </BlockPage>
  }

  return page;
};

export default StatePage;
