import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";

import Map from "./interactive-map";
import NavigationDots from "./nav-dots";
import { BlockPage, Block, BlockTitle, BlockText } from "./block-system";
import MapWrapper from "./map-wrapper";
import Plot from "./plot";
import { capitalize } from "./utilities";

import districtMapData from "./district-map-data.json";
import senateMapData from "./senate-map-data.json";

const ChamberPage = (props) => {

  const { chamber } = props;

  // const [isLoaded, setIsLoaded] = React.useState(false);
  // const [data, setData] = React.useState(undefined);

  // const getData = () => {
  //   setIsLoaded(false);
  //   Axios.get("http://localhost:4022/states")
  //     .then(response => {
  //       setData(response.data);
  //       setIsLoaded(true);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [chamber])

  // useEffect(() => {
  //   getData();
  // }, [])

  // const CardContainer = styled.div`
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   width: 100%;
  //   margin-bottom: 50px;
  // `;

  // var cards;
  // if (true) {
  //   cards =
  //     <CardContainer>
  //       {data.map((item, i) => (
  //         <Link key={i} to={"/house/" + item.state_code + "-districts"} >
  //           <StateCard key={i} data={item} />
  //         </Link>
  //       ))}
  //     </CardContainer>
  // }

  // const MakeTooltipHouse = (d) => {
  //   return (
  //     `<div class='map-tooltip__content'>
  //       <div class='map-tooltip__score-container'>
  //         <div class='${d.properties.party_code === "R" ? "map-tooltip__score--republican" : "map-tooltip__score--democrat"}'>${d.properties.score_formatted === null ? "?" : d.properties.score_formatted}</div>
  //       </div>
  //       <div class='map-tooltip__text-container'>
  //         <div class='map-tooltip__member'>${d.properties.first_name} ${d.properties.last_name} <span class='${d.properties.party_code === "R" ? "map-tooltip__party--republican" : "map-tooltip__party--democrat"}'>(${d.properties.party_code})</span></div>
  //         <div class='map-tooltip__state'>${d.properties.state}, ${d.properties.district}</div>
  //       </div>
  //     </div>`
  //   )
  // }

  // const MakeTooltipSenate = (d) => {
  //   return (
  //     `<div style='display:flex;flex-direction:column;'>
  //     <div class='map-tooltip__content'>
  //       <div class='map-tooltip__score-container'>
  //         <div class='${d.properties.senate_party_code_1 === "R" ? "map-tooltip__score--republican" : "map-tooltip__score--democrat"}'>${d.properties.senate_score_formatted_1 === null ? "?" : d.properties.senate_score_formatted_1}</div>
  //       </div>
  //       <div class='map-tooltip__text-container'>
  //         <div class='map-tooltip__member'>${d.properties.senate_first_name_1} ${d.properties.senate_last_name_1} <span class='${d.properties.senate_party_code_1 === "R" ? "map-tooltip__party--republican" : "map-tooltip__party--democrat"}'>(${d.properties.senate_party_code_1})</span></div>
  //         <div class='map-tooltip__state'>${d.properties.state}
  //         </div>
  //       </div>
  //     </div>
  //     <div class='map-tooltip__content'>
  //       <div class='map-tooltip__score-container'>
  //         <div class='${d.properties.senate_party_code_2 === "R" ? "map-tooltip__score--republican" : "map-tooltip__score--democrat"}'>${d.properties.senate_score_formatted_2 === null ? "?" : d.properties.senate_score_formatted_2}</div>
  //       </div>
  //       <div class='map-tooltip__text-container'>
  //         <div class='map-tooltip__member'>${d.properties.senate_first_name_2} ${d.properties.senate_last_name_2} <span class='${d.properties.senate_party_code_2 === "R" ? "map-tooltip__party--republican" : "map-tooltip__party--democrat"}'>(${d.properties.senate_party_code_2})</span></div>
  //         <div class='map-tooltip__state'>${d.properties.state}
  //         </div>
  //       </div>
  //     </div>
  //   </div>`
  //   )
  // }

  const navData = [
    { id: "block-1", label: ("Partisanship in the " + capitalize(chamber)) },
    { id: "block-2", label: "Partisanship by seat" },
    { id: "block-3", label: (chamber === "house" ? "Partisanship by district" : "Partisanship by state") },
    { id: "block-4", label: "Partisanship by member" },
    { id: "block-5", label: "Partisanship by party" },
    { id: "block-6", label: "Speeches by member" },
  ];

  var intro;
  if (chamber === "house") {
    intro =
      <Block id="block-1" width={700} color="white" nextColor="light" shape={1}>
        <BlockTitle>
          Political Partisanship in the United States House of Representatives
        </BlockTitle>
        <BlockText>
          The House of Representatives is the lower house of Congress. The number of seats per state is based on the state's population, as measured by the US Census. There are currently 435 seats. Representatives serve 2-year terms and there are no term limits. Due to the large number of members, the House has strict debate rules to keep order.
        </BlockText>
        <BlockText>
          Historically, the House has generally been more partisan than the Senate. Currently, however, the Senate is slightly more partisan than the House, on average. This is largely because there are a significant number of relatively non-partisan Democrats in the House who had to win competitive mid-term elections.
        </BlockText>
        <BlockText>
          The average Partisanship.org score of current representatives in the House, setting aside the polarity of the score, is 4.19. Republican members are significantly more partisan than Democratic senators. The average score for Democrats is -3.49, and the average score for Republicans is +4.86. In general, members of opposition parties — especially in lower houses — are more partisan than members of governing parties because it's easier for opposition parties to coalesce around a message attacking the governing party.
        </BlockText>
        <BlockText>
          Check out the data visualizations below to learn more about partisanship in the House in the 118th Congress.
        </BlockText>
      </Block>
  }

  if (chamber === "senate") {
    intro =
      <Block id="block-1" width={700} color="white" nextColor="light" shape={1}>
        <BlockTitle>
          Political Partisanship in the United States Senate
        </BlockTitle>
        <BlockText>
          The Senate is the upper house of Congress. There are two seats per state for a total of 100 seats. Senators server 6-year terms and there are no term limits. With fewer members than the House, the Senate has more free-flowing debate rules. And because of longer term limits, there is generally more docorum in the Senate than in the House. Historically, the Senate has generally been less partisan than the House.
        </BlockText>
        <BlockText>
          The average Partisanship.org score of current senators, setting aside the polarity of the score, is 4.61. Republican senators are slightly more partisan than Democratic senators. The average score for Democrats is -4.40, and the average score for Republicans is +4.85.
        </BlockText>
        <BlockText>
          Check out the data visualizations below to learn more about partisanship in the Senate in the 118th Congress.
        </BlockText>
      </Block>
  }

  var map;
  if (chamber === "house") {
    map =
      <Block id="block-3" width={700} color="white" nextColor="light" shape={1}>
        <Plot
          title="Partisanship by district"
          subtitle="The geographic distribution of Partisanship.org scores by House district for representatives in the 118th Congress (2023-present)"
          map={
            <MapWrapper data={districtMapData} type="house" />
          }
        />
        <BlockText>
          This map shows the Partisanship.org score for each member of the House. The color of each district indicates the member's Partisanship.org score. Darker blues indicate more partisan Democrats and darker reds indicate more partisan Republicans. The color is based on the percentile of the score within the member's party. The gray color indicates that there's not enough data yet to estimate an accurate score for the member.
        </BlockText>
        <BlockText>
          This map is interactive. If you're on a computer, you can hover your mouse over each district to bring up a tooltip that shows the Partisanship.org score for the member who represents that district. If you're using a trackpad, you can two-finger zoom or pinch zoom to zoom in and out. If you're using a mouse, you can use your scroll button. You can click and drag to pan the map.
        </BlockText>
      </Block>
  }
  if (chamber === "senate") {
    map =
      <Block id="block-3" width={700} color="white" nextColor="light" shape={1}>
        <Plot
          title="Partisanship by state"
          subtitle="The geographic distribution of Partisanship.org scores by state for senators in the 118th Congress (2023-present)"
          map={
            <MapWrapper data={senateMapData} type="senate" />
          }
        />
        <BlockText>
          This map shows the Partisanship.org score for each member of the Senate. The color of each state indicates the average Partisanship.org score for the state's two senators. Darker blues indicate more partisan Democrats and darker reds indicate more partisan Republicans. The color is based on the percentile of the score within the member's party.
        </BlockText>
        <BlockText>
          This map is interactive. If you're on a computer, you can hover your mouse over each state to bring up a tooltip that shows the Partisanship.org scores for the two senators who represent that state. If you're using a trackpad, you can two-finger zoom or pinch zoom to zoom in and out. If you're using a mouse, you can use your scroll button. When you're zoomed in, you can click and drag to pan the map.
        </BlockText>
      </Block>
  }

  const page =
    <BlockPage>
      {intro}
      <Block id="block-2" width={700} color="light" nextColor="white" shape={1}>
        <Plot
          title="Partisanship by seat"
          subtitle={
            <span>
              The distribution of Partisanship.org scores in the {capitalize(chamber)} by seat in the 118th Congress (2023-present)
            </span>
          }
          path={"plots/chamber-plots/" + chamber + "-seats.svg"}
        />
        <BlockText>
          This visualization shows the Partisanship.org score for each member of the {capitalize(chamber)} in the 118th Congress by seat. Members are sorted by score, with the more non-partisan members closer to the middle and the more partisan members closer to the edges. Democrats are on the left and Republicans are on the right.
        </BlockText>
        <BlockText>
          The average score for Democrats is {chamber == "house" ? "-3.49" : "-4.40"}, and the average score for Republicans is {chamber == "house" ? "+4.86" : "+4.85"}. The magnitude of the average score is larger for Republicans than Democrats. This means that Republicans are relatively more partisan than Democrats — it's easier to guess a member's party based on their speech for Republicans than it is for Democrats.
        </BlockText>
      </Block>
      {map}
      <Block id="block-4" width={700} color="light" nextColor="white" shape={1}>
        <Plot
          title="Partisanship by member"
          subtitle={
            <span>
              Estimated Partisanship.org scores for all members of the {capitalize(chamber)} in the 118th Congress (2023-present)
            </span>
          }
          path={"plots/chamber-plots/" + chamber + "-scores.svg"}
        />
        <BlockText>
          This visualization shows the Partisanship.org score for each member of the {capitalize(chamber)}. The x-axis (horizontal) is a member's Partisanship.org score. The y-axis (vertical) doesn't have an interpretation. Members are distributed on the y-axis to make the plot easier to read. The size of each circle indicates how many paragraphs of text a member's score is based on. The more text a member has, the more confident we can be that their Partisanship.org score accurately characterizes how partisan they are in their legislative speeches.
        </BlockText>
      </Block>
      <Block id="block-5" width={700} color="white" nextColor="light" shape={1}>
        <Plot
          title="Partisanship by party"
          subtitle={
            <span>
              The distribution of Partisanship.org scores in the {capitalize(chamber)} by political party in the 118th Congress (2023-present)
            </span>
          }
          path={"plots/chamber-plots/" + chamber + "-density.svg"}
        />
        <BlockText>
          This visualization shows how polarized the parties are in the {capitalize(chamber)}. It shows the distribution of member-level Partisanship.org scores for all members of the {capitalize(chamber)} in the 118th Congress. The x-axis (horizontal) shows Partisanship.org scores. The y-axis (vertical) shows the density — how many members there around around a particular score. The distribution of scores for Democrats is in blue, and the distribution of scores for Republicans is in red.
        </BlockText>
        <BlockText>
          There's very little overlap between the distributions. The more they overlap, the less polarized the {capitalize(chamber)} is. Note that neither party's distribution extends all the way to the outer edge. Member-level Partisanship.org scores are weighted averages of paragraph-level scores, and members usually say at least some non-partisan things, so the magnitude of member-level Partisanship.org scores generally stays below 8.
        </BlockText>
        <BlockText>
          {chamber === "house" ? "In the House, the distribution for Democrats is closer to the center than the distribution for Republicans, which means that Republicans are relatively more partisan than Democrats — it's easier to guess a member's party based on their speech for Repbulicans than it is for Democrats. The distribution for Republicans is narrower, which means that Republicans are more cohesive in thier messaging than Democrats are." : "In the Senate, the distribution for each party is equally far from the center, which means that Republicans and Democrats are equally partisan. The width of the distributions are also similar, which means both parties are similarly cohesive in their messaging."}
        </BlockText>
      </Block>
      <Block id="block-6" width={700} color="light" nextColor="dark" shape={2}>
        <Plot
          title="Speeches by member"
          subtitle={
            <span>
              Total words spoken by each member of the {capitalize(chamber)} during the 118th Congress (2023-present)
            </span>
          }
          caption=""
          path={"plots/chamber-plots/" + chamber + "-speeches.svg"}
        />
        <BlockText>
          This visualization shows all floor speaches by members of the {capitalize(chamber)} during the 118th Congress. There's one circle for each member of the {capitalize(chamber)}. The size of each circle indicates the total number of words spoken by the member to date in this Congress. The color of each circle indicates the member's Partisanship.org score. This is the average score for all paragraphs in their legislative speeches. Darker shades of blue indicate more partisan Democrats and darker shades of red indicate more partisan Republicans. The 15 members who have spoken the most are labeled.
        </BlockText>
      </Block>
      <NavigationDots data={navData} />
    </BlockPage>

  return page;
};

export default ChamberPage; 
